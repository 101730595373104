import React from "react";


import VerifySection from "../components/Sections/Verify";


export default function Verify() {
  return (
    <>
      <VerifySection/>
    </>
  );
}


