import React from 'react';
import ImageSlider from './ImageSlider';
import OneP from "../../assets/img/projects/t1.jpg";
import TwoP from "../../assets/img/projects/t2.jpg";
import ThreeP from "../../assets/img/projects/t3.jpg";
import FourP from "../../assets/img/projects/t4.jpg";

const images = [
  OneP,
  TwoP,
  ThreeP,
 
];

const MyImages = () => {
  return (
    <div className="app">
      
      <ImageSlider images={images} />
    </div>
  );
};

export default MyImages;