import React from "react";


import SignupSection from "../components/Sections/Signup";


export default function Signup() {
  return (
    <>
      <SignupSection/>
    </>
  );
}


