import React from "react";


import ForgotSection from "../components/Sections/Forgot";
export default function Forgot() {
  return (
    <>
      <ForgotSection/>
    </>
  );
}


