import React, { useState } from 'react';
import UserTable from "./component/userTable";
import ProjectTable from "./component/projectTable";
import Booking from "./component/booking"
import "../../style/admin.css"
const Admin = () => {
  return (
    <>
<div class="app body">

	<div class="app-body">
		<div class="app-body-navigation">
		</div>
		<div class="app-body-main-content">
			<section class="service-section">
				<h2>Admin</h2>

				<div class="tiles">
					<article class="tile">
						<div class="tile-header">
							<i class="ph-lightning-light"></i>
							<h3>
								<span>Users</span>
								<span>23,000</span>
							</h3>
						</div>

					</article>
					<article class="tile">
						<div class="tile-header">
							<i class="ph-fire-simple-light"></i>
							<h3>
								<span>All projects</span>
								<span>70979</span>
							</h3>
						</div>
						<a href="#">
							<span>see more</span>
							<span class="icon-button">
								<i class="ph-caret-right-bold"></i>
							</span>
						</a>
					</article>
					<article class="tile">
						<div class="tile-header">
							<i class="ph-file-light"></i>
							<h3>
								<span>Booked projects</span>
								<span>90,000.</span>
							</h3>
						</div>
						<a href="#">
							<span>Go to service</span>
							<span class="icon-button">
								<i class="ph-caret-right-bold"></i>
							</span>
						</a>
					</article>
<Booking/>
										<article class="tile">
						<div class="tile-header">
							<i class="ph-file-light"></i>
							<h3>
								<span>Ongoing projects</span>
								<span>90,000.</span>
							</h3>
						</div>
						<a href="#">
							<span>Go to service</span>
							<span class="icon-button">
								<i class="ph-caret-right-bold"></i>
							</span>
						</a>
					</article>
										<article class="tile">
						<div class="tile-header">
							<i class="ph-file-light"></i>
							<h3>
								<span>Finished projects</span>
								<span>90,000.</span>
							</h3>
						</div>
						<a href="#">
							<span>Go to service</span>
							<span class="icon-button">
								<i class="ph-caret-right-bold"></i>
							</span>
						</a>
					</article>
				</div>

			</section>

<UserTable/>
<ProjectTable/>
		</div>

	</div>
</div>
</>
)
}
export default Admin