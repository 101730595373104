import React, { useState, useEffect } from "react";
import axios from "axios";

const UserTable = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post("https://ni-experiences.com/server/data.php");

        setUsers(response.data.users);
        console.log(response.data.users);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

const toggleActive = async (user) => {
  const updatedUsers = users.map((u) =>
    u.user_id === user.user_id ? { ...u, status: u.status === "1" ? "0" : "1" } : u
  );

  setUsers(updatedUsers);

  // Update user status in the database
  try {
    await axios.post("https://ni-experiences.com/server/updateStatus.php", {
      userId: user.user_id,
      status: updatedUsers.find((u) => u.user_id === user.user_id).status
    });
    console.log("User status updated successfully");
  } catch (error) {
    console.error("Error updating user status:", error);
  }
};

  return (
    <section className="transfer-section">
      <div className="transfer-section-header">
        <h2>Users</h2>
        <div className="filter-options">
          <button className="button">
            <i className="ph-plus"></i>
          </button>
        </div>
      </div>
      <div className="table-container-scrollable">
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone number</th>
                <th>Interest</th>
                <th>Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.length>0 ? (users.map((item, index) => (
                <tr key={index}>
                  <td>{item.username}</td>
                  <td>{item.email}</td>
                  <td>{item.phone_number}</td>
                  <td>{item.Interest}</td>
                  <td>{item.created_at}</td>
                  <td>
                    <button className="save-button" onClick={() => toggleActive(item)}>
                      {item.status === 1 ? "Deactivate" : "Activate"}
                    </button>
                  </td>
                </tr>
              ))):(<p>loading...</p>)}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default UserTable;