import React from "react";
import "./style.css"
import LeftContent from "./left";
import RightContent from "./right";
import MainMenue from "./nav";
const Dashboard = () => {
  return (
    <section className="contentor">
  
      <LeftContent />
      <br/><br/><br/><br/>
      <RightContent />
    </section>
  );
};

export default Dashboard;