import React from "react";
// Sections
import ProjectHeader from "../components/Sections/ProjectHeader"
import Projects from "../components/Sections/Projects";

import Community from "../components/Sections/Community";
const Project= () => {
    return (
        <>
            <ProjectHeader/>
               <Projects />
          <Community/>
        </>
    )
}

export default Project