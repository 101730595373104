export const SearchData: Project[] = [
  {
    id: 1,
    name: "AI Research Project",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  },
  {
    id: 2,
    name: "Green Energy Initiative",
    description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  },
  // Add more projects as needed
];