import React from "react";
import "./style.css"
import MyImages from "../../components/dash/projectImages"
import FiveP from "../../assets/img/projects/t5.jpg";
const Details= () => {
  
    return(
   
   <div className = "detailer">
<div className="bg-white md:mx-auto rounded padding shadow-xl ">
  <div className="h-[140px] bg-gradient-to-r from-cyan-500 to-blue-500"></div>
  <div className="px-5 py-2 flex flex-col gap-3 pb-6">
    <div className="  border-4 overflow-hidden -mt-14 border-white"><img width="100" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4ú.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" className="rounded-full object-center object-cover"/></div>
    <div className="">
      <h3 className="text-xl text-slate-900 relative font-bold leading-6">Digital financial inclusion</h3>
      <p className="text-sm text-gray-600">Utlize your computer skills to make every african acess banking services</p>
    </div>
    <div className="flex gap-3 flex-wrap"><span className="rounded-sm bg-yellow-100 px-3 py-1 text-xs font-medium text-yellow-800">Developer</span><span className="rounded-sm bg-green-100 px-3 py-1 text-xs font-medium text-green-800">Data science</span><span className="rounded-sm bg-blue-100 px-3 py-1 text-xs font-medium text-blue-800">Payment systems</span><span className="rounded-sm bg-indigo-100 px-3 py-1 text-xs font-medium text-indigo-800">Projects</span></div>
    <div className="flex gap-2"><button type="button" className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-800 transition hover:border-gray-300 active:bg-white hover:bg-gray-100 focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300">Schedule call</button><button type="button" className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded border border-gray-200 bg-blue-700 px-3 py-2 text-sm font-medium text-white transition hover:border-blue-300 hover:bg-blue-600 active:bg-blue-700 focus:blue-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-300">Book project</button></div>
    
<MyImages/>
    
    <h4 className="text-md font-medium leading-3">About</h4>
    <p className="text-sm text-stone-500">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere dolores aliquid sequi sunt iusto ipsum earum natus omnis asperiores architecto praesentium dignissimos pariatur, ipsa cum? Voluptate vero eius at voluptas?</p>
    <br/>
    <h4 className="text-md font-medium leading-3">Why choose this project</h4>
    <p className="text-sm text-stone-500">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere dolores aliquid sequi sunt iusto ipsum earum natus omnis asperiores architecto praesentium dignissimos pariatur, ipsa cum? Voluptate vero eius at voluptas?</p>
    <br/>
    <h4 className="text-md font-medium leading-3">skill sets</h4>
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-3 px-2 py-3 bg-white rounded border w-full ">

        <div className="leading-3">
          <p className=" text-sm font-bold text-slate-700">Ui Designer</p><span className="text-xs text-slate-600">3-8 hours a week</span>
        </div>
        <p className="text-sm text-slate-500 self-start ml-auto">As Ui Designer on Front Page</p>
      </div>
      <div className="flex items-center gap-3 px-2 py-3 bg-white rounded border w-full ">

        <div className="leading-3">
          <p className=" text-sm font-bold text-slate-700">Project Managements</p><span className="text-xs text-slate-600"> 5 hours per week</span>
        </div>
        <p className="text-sm text-slate-500 self-start ml-auto">As Ui Designer on Front Page</p>
      </div>
      <div className="flex items-center gap-3 px-2 py-3 bg-white rounded border w-full ">

        <div className="leading-3">
          <p className=" text-sm font-bold text-slate-700">Communication</p><span className="text-xs text-slate-600">5 hours oer week</span>
        </div>
        <p className="text-sm text-slate-500 self-start ml-auto">As Ui Designer on Front Page</p>
      </div>
    </div>
  </div>
</div>
</div>
      
      
      );
};

export default Details;