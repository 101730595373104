import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const LeftContent = () => {
  
  const [isOpen, setIsOpen] = useState(false);
const [isAdmin, setAdmin] = useState(true);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="right-content">
      <div className="user-info">
 <div className="icon-container" onClick={toggleDropdown}>
      <i className="fa fa-user nav-icon"></i>
      {isOpen && (
        <div className="dropdown-content">
          <a href="#">Settings</a>
           {isAdmin &&( <Link to="/admin">Admin</Link>)}
           <a href="#">Help center</a>

          {/* Add more dropdown items as needed */}
        </div>
      )}
    </div>
        <h4>Makerere</h4>
        <img src="https://ni-experiences.com/static/media/logow.eaf4413c.png" alt="user" />
      </div>

      <div className="active-calories">
        <h1 style={{ alignSelf: "flex-start" }}>overview</h1>
        <div className="active-calories-container">
          <div className="box" style={{ "--i": "85%" }}>
          
          </div>
          <div className="calories-content">
            <p><span>My projects:</span> 40</p>
            <p><span>completed:</span> 30</p>
            <p><span>ongoing:</span> 10</p>
          </div>
        </div>
      </div>

      <div className="mobile-personal-bests">
        <h1>My projects</h1>
        <div className="personal-bests-container">
          <div className="best-item box-one">
            <p>Coffee farming and export rate in Africa: uganda</p>
            <img src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/05dfc444-9ed3-44cc-96af-a9cf195f5820" alt="" />
          </div>
          <div className="best-item box-two">
            <p>Maping roads: Uganda </p>
            <img src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/9ca170e9-1252-4fa6-8677-36493540c1f2" alt="" />
          </div>
          <div className="best-item box-three">
            <p>Provision of safe water for drinking: Kenya</p>
            <img src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/262d1611-ed4c-4297-981c-480cf7f95714" alt="" />
          </div>

        </div>
      </div>
    </div>
  );
};

export default LeftContent;