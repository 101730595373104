import React from "react";
import styled from "styled-components";
// Assets
import QuoteIcon from "../../assets/svg/Quotes";

export default function TestimonialBox({ text, author, pic , location}) {
  return (
    <Wrapper className="liBg radius8 flexNullCenter flexRow">
      <ProfilePicture src={pic} alt="User Profile" />
      <ContentWrapper>
        <QuoteWrapper>
          <QuoteIcon />
        </QuoteWrapper>
       <p className="darkColor font15 font-semibold">{text}</p>
        <LocationWrapper>
          <p className="orangeColor font13" style={{ alignSelf: "flex-end" }}>
            <em>{author}</em>
          </p>
          <LocationText>
            {location}
          </LocationText>
        </LocationWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  margin-top: 30px;
`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

const QuoteWrapper = styled.div`
  position: relative;
  top: -40px;
`;

const LocationWrapper = styled.div`
  position: relative;
`;

const LocationText = styled.p`
  position: relative;
  z-index: 1;
  color:#022D36;
  font-size: 10px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.2); /* Faint black background */
  border-radius: 20px;
  margin: 0;
`;