import React from "react";


import Contact from "../components/Sections/Contact";


export default function ContactUs() {
  return (
    <>
    
      
      
      <Contact/>
    </>
  );
}


