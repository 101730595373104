import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../assets/img/clients/p1.png"
import ClientLogo02 from "../../assets/img/clients/p2.png";
import ClientLogo03 from "../../assets/img/clients/p3.png";
import ClientLogo04 from "../../assets/img/clients/p4.png";
import ClientLogo05 from "../../assets/img/clients/p5.png";
import ClientLogo06 from "../../assets/img/clients/p6.png";
export default function ClientSlider() {
const settings = {
  infinite: true,
  speed: 400,
  slidesToShow: 6,
  slidesToScroll: 2,
  arrows: false,
  autoplay: true, // Enable autoplay
  autoplaySpeed: 1000, // Decreased autoplay speed to 2000 milliseconds (2 seconds)
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
  return (
    <div style={{height:"200px"}}className="darkBg">
    <br/><br/>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo01} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo02} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo03} alt="client logo" />
        </LogoWrapper>
                <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo04} alt="client logo" />
        </LogoWrapper>
                <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo05} alt="client logo" />
        </LogoWrapper>
                <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo06} alt="client logo" />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 200px;
  padding: 10%;
`;