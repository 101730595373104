import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";

// Assets

export default function Services() {
  return (
    <Wrapper id="services">

      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 flexCenter extraBold">
            What  does index Offer?
            </h1>
            <p className="font18 textCenter flexCenter">
Are you an education institution or faculty looking for industry experience for your students? Or a student looking to solve problems that match your interests and career prospects? This is how index can support you through the process.
            
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Vetted"
                subtitle="Our platform has a pool of carefully selected industry projects to provide students with valuable experience."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Industry"
                subtitle="We collaborate with universities to align industry projects with academic curriculum, ensuring students gain relevant skills and knowledge."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Exchange"
                subtitle="opportunities with students being able to collaborate with other students from other universities on project activities.
                "
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer" title="Customization" subtitle="We tailor project opportunities to match students' skills and interests, maximizing their learning and development" />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
    <ClientSlider/>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  media (max-width: 760px) {
   
  }
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
    margin-top:-180px;
  }
`;
