import React from "react";


import ChangeSection from "../components/Sections/change";

export default function Change() {
  return (
    <>
      <ChangeSection/>
    </>
  );
}


