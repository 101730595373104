import React from "react";
//import "../../../style/admin.css"

const Booking= () => (
										<article class="tile">
						<div class="tile-header">
							<i class="ph-file-light"></i>
							<h3>
								<span>Booking requests</span>
								<span>90,000.</span>
							</h3>
						</div>
						<a href="#">
							<span>see more</span>
							<span class="icon-button">
								<i class="ph-caret-right-bold"></i>
							</span>
						</a>
					</article>
);

export default Booking;