import ProjectImg1 from "../../assets/img/projects/p1.jpg";
import ProjectImg2 from "../../assets/img/projects/p2.jpg";
import ProjectImg3 from "../../assets/img/projects/p6.jpg";
import ProjectImg4 from "../../assets/img/projects/p4.jpg";
import ProjectImg5 from "../../assets/img/projects/p5.jpg";
import ProjectImg6 from "../../assets/img/projects/p3.jpg";

import Test1 from "../../assets/img/test/project4.png";
import Test2 from "../../assets/img/test/project3.jpeg";
import Test3 from "../../assets/img/test/project2.png";
import Test4 from "../../assets/img/test/project2.jpg";
import Test5 from "../../assets/img/test/project5.png";
export const NavLinks = [
    { to: "/", text: "Home", offset: -80 },
    { to: "/about", text: "About", offset: -80 },
    { to: "/projects", text: "Projects", offset: -80 },
    { to: "/contact", text: "Contact", offset: -80 },
    { to: "/faqs", text: "FAQs", offset: -80 }
  ];
export const NavButtons = [
    {
        name: "Login",
        url: "/login"
    },
    {
        name: "Signup",
        url: "/signup"
    }

];
export const LoginTexts ={
  
  firstText: "Login",
  emailPlaceholder: "Email",
  passwordPlaceholder: "Password",
  loginButton: "Login",
  submitButton: "send",
  googleLoginButton: "Login with Google"
}
export const SignupTexts ={
  
  firstText: "Signup",
  emailPlaceholder: "Email",
  passwordPlaceholder: "Password",
  loginButton: "Register",
  googleLoginButton: "Signup with Google"
}
export const HeroTexts = {
    firstText: "Unlock your innovation, research, professional & academic potential with  ∩  Industry Experiences. ",
    secondText: "n-industry Experiences.",
    thirdText: "The easiest way for students and professionals to find and collaborate on projects designed by industry and impact first organisations.",
    firstButton: "Find out more",
    secondButton: 'Play Demo' 
}
export const ProjectTexts = {
    firstText: "Browse through different types of projects",
    secondText: "Our Projects.",
    thirdText: "Hustle free way for young professionals & researchers to find work and work on industry relavant problems.",
    firstButton: "Search",
    
}
export const ContactTexts = {
    firstText: "we are available 24/7 talk to us",
    secondText: "Contact us",
    thirdText: "Get in touch with the  ∩  Industry Experiences Team.",
    EmailText: "Email",
    PhoneText:"Call" ,
  
}
export const AboutTexts = {
    firstText: "Our passion is to bring together industry & academia to create meaningful impact.",
    secondText: "About us",
    thirdText: "We empower early career professionals & researchers to use their knowledge and skills to solve the world’s most pressing challenges designed by real industries & impact first organizations.",
    firstButton:"Get in touch"
  
}
export const FaqTexts = {
    firstText: "Here are some of the frequently asked questions",
    secondText:"FAQs",
    questions: [
        {
            question: "What does  ∩  Industry Experiences do?",
            answer: " ∩  is a platform that provides students, and universities with experiential learning opportunities through projects designed by industry and impact first organisations."
        },
        {
            question: "What is an industry experience?",
            answer: "An Industry experience refers to the practical knowledge and skills gained while working in a particular field or occupation. Internships, work placements, and co-op programs are great avenues to gain work experience as student."
        },
        {
            question: "Do you offer virtual or in-person experiences?",
            answer: " ∩  offers both virtual & in-person work experiences. Our in-person experiences are only limited to Uganda, and we are expanding our offerings to Kenya & Tanzania."
        },
        {
            question: "How are industry experiences crafted?",
            answer: "Our experiences are crafted by industry and organisations working at the grassroots by identifying an operational or community problem in a particular sector they work in."
        },
        {
            question: "Will I be paid for undertaking an industry experience?",
            answer: "Most of our industry experiences are unpaid. However, if an experience comes with an incentive, we shall indicate that on the list of expectations or benefits."
        },
        {
            question: "How do I sign up?",
            answer: "Click Sign Up to create your  ∩  account, we will ask you a few questions to find out more t you so that we can suggest the best experiences for you and then you’re free to browse & use our platform."
        },
        {
            question: "Do I get involved with a company or organisation?",
            answer: "Not like other placements, you work directly with a mentor or industry professional with experience in that field."
        },
        {
            question: "Do companies apply what I develop with them during the experience?",
            answer: "Your ideas & methods support the company or organisation you are involved with!"
        },
        {
            question: "How do you collect my data? And what do you do with it?",
            answer: "We treat the collection and storage of your data very seriously. To be effective, we will collect some data on you to help us better customise industry experiences for you. We encourage you to read our privacy notice here and our terms of use."
        }
    ]
};
export const ServiceTexts = {
    firstText: "CATEGORY",
    secondText: "We Offer Best Services",
    cards: [
        {
            firstText: "Vetted Projects",
            secondText: "Our platform has a pool of carefully selected industry projects to provide students with valuable experience."
        },
        {
            firstText: "Industry Connections",
            secondText: "We facilitate partnerships with leading companies and government organizations, offering students real-world exposure and networking opportunities."
        },
        {
            firstText: "Academic Collaboration",
            secondText: "We collaborate with universities to align industry projects with academic curriculum, ensuring students gain relevant skills and knowledge."
        },
        {
            firstText: "Customization",
            secondText: "We tailor project opportunities to match students' skills and interests, maximizing their learning and development."
        }
    ]
}
export const TopDestinationTexts = {
    firstText: "Top Projects",
    secondText: "Most Sought-After Industry Opportunities",
    cards: [
        {
            country: " Develop street maps for a new secondary city in Uganda.",
            type: "Tech innovation",
            status: "Booked"
        },
        {
            country: " Improve access to safe water through protected springs in Uganda",
            type: "Research Project",
            status: "pending"
        },
        {
            country: " Develop a citizen-government digital interaction platform.",
            type: "Collaborative Project",
            status: "ongoing"
        },
        {
            country: "Improve sustainability of hand pumps through a digital solution",
            type: "Water and sanitation",
            status: "ongoing"
        },

    ]
}
export const BookingStepsTexts = {
    firstText: "Easy and Fast",
    secondText: "Book your next industry project in 3 easy steps",
    listOfSteps: [
        {
            text: "Browse Projects: Discover a wide range of industry projects available for students."
        },
        {
            text: "Apply: Submit your application for the project that interests you."
        },
        {
            text: "Confirmation: Receive confirmation of your selection and start your project."
        }
    ],
    cardOne:
    {
        name: "Develop street maps for a new secondary city in Uganda",
        date: "Starts on March 15 | by Tech Innovations Ltd.",
        spots: "on going"
    },
    cardTwo: {
        status: "Ongoing",
        project: "Green Energy Initiative",
        progress: "Booked",
        
    }
}
export const BookMeetingText = {
    firstText: "Do you have a project?",
    secondText: "lets handle yiur project in three easy steps",
    buttonText: "Book a meeting",
    buttonText2: "Submit a project",
    listOfSteps: [
        {
            text: "Request Meeting: Fill out a simple form to request a meeting with our team."
        },
        {
            text: "Confirmation: Receive confirmation of your meeting request."
        },
        {
            text: "Meeting: Meet with our experts to discuss your project requirements and goals."
        }
    ],
    cardOne:
    {
        name: "AI Research Project",
        date: "Starts on March 15 | by Tech Innovations Ltd.",
        spots: "on going "
    },
    cardTwo: {
        status: "Ongoing",
        project: "Green Energy Initiative",
        progress: "Booked"
    }
}
export const CommunityTexts = {
    firstText: "Click on the links below to join our global community",
    secondText: "Join our community",
    buttonText: "Book a meeting",
    buttonText2: "Submit a project",
    listOfSteps: [
        {
            text: "Join our Facebook community by joining our groups",
            link: "https://facebook.com/groups/1843957289391103/",
        },
        
        {
            text: "follow us our WhatsApp channels ",
            link: "https://whatsapp.com/channel/0029VaEHoKLFy72A8iPcEJ2T",
        }
    ],
    cardOne:
    {
        name: "AI Research Project",
        date: "Starts on March 15 | by Tech Innovations Ltd.",
        spots: "on going "
    },
    cardTwo: {
        status: "Ongoing",
        project: "Green Energy Initiative",
        progress: "Booked"
    }
}
export const ProductTexts = {
  
    firstTextMajor: "Our Philosophy",
    subFirst: "We believe industry plays a huge role in producing job-ready graduates for the present & the future. We believe industry should shape what is taught in the classroom. We make finding talent & industry engagement opportunities easy. We put the needs of both the students, universities & industry at the heart of everything we do.",
    secondTextMajor: "Our Product",
    subSecond: " ∩  Industry Experiences connects students & researchers to industry & vice versa. Through  ∩ , students & universities can browse and book experiential learning opportunities to work on real-world projects run by industry experts. An Industry expert works with a student or student group in semester breaks or during the semester to enable learning by doing.",
    thirdTextMajor: "How it works",
    subThird: "The hassle-free way to find virtual & in-person experiential learning opportunities organized by industry.",
    buttonText: "Book a meeting",
    buttonText2: "Submit a project",
    listOfSteps: [
        {
          head:"Project",
            text: "Access short-term impactful projects covering a wide range of topics including, but not limited to healthcare, food & agriculture, clean energy, education, water, digitalization, urban planning, environment, transport & finance",
            link: "/project",
            icon: "fas fa-brush"
        },
        {
          head: "Schedule call",
            text: "Schedule hours of knowledge exchange, discuss expectations, get project design feedback, test your ideas with a real partner, know the impact of your work, get LinkedIn & resume recommendations & grow your potential employer network.",
            link: "https://whatsapp.com/channel/0029VaEHoKLFy72A8iPcEJ2T",
            icon: "fas fa-wrench",
        },
        {
         head: "Discover",
            text: "Discover potential final year industry designed research topics or get industry input into your research topic.",
            link: "/project",
            icon: "fas fa-truck-pickup"
        },
        {
          head: "Talk to us",
            text: "Schedule an in-person industry & cultural immersion experience to help you or a group of students get a hands-on experience & mix with people facing or solving problems of your interest.",
            link: "/contact",
            icon: "fas fa-phone"
        }
    ],
    cardOne: {
        name: "AI Research Project",
        date: "Starts on March 15 | by Tech Innovations Ltd.",
        spots: "on going "
    },
    cardTwo: {
        status: "Ongoing",
        project: "Green Energy Initiative",
        progress: "Booked"
    }
}
export const AboutLowerTexts = {
    firstText: "VALUES",
    secondText: "Our Core Principles",
    cards: [
        {
            firstText: "Creativity & Innovation",
            secondText: "We challenge the status quo to create unique & challenging learning experiences."
        },
        {
            firstText: "Trust",
            secondText: "We believe everyone on our team & partners can deliver work that reflects the standards that meet expectations of our clients."
        },
        {
            firstText: "Integrity",
            secondText: "We are an honest team that up-holds moral values while doing work and interacting with customers."
        },
        {
            firstText: "Accountability",
            secondText: "We are answerable to all our stakeholders as a result of all the results & actions we take."
        },
        {
            firstText: "Respect",
            secondText: "We treat everyone with respect irrespective of their age, color, sex, ethnicity, disability religion, & origin."
        }
    ]
};
export const TestimonialTexts = {
    firstText: "TESTIMONIALS",
    secondText: "What our Alumni say",
    feedBacks: [
        {
            text: "Through the study trip to Uganda, I was reminded that engineering is intrinsic to supporting and helping people.",
            person: "Aretha Ahunanya",
            location: "MEng Engineering & Architectural Design-  University College London",
            pic: Test4
        },
        {
            text: "My time in Uganda solidified my belief that engineering is not just about building structures or designing systems but its about improving lives.",
            person: "Sanmugi",
            location: "Civil and Environmental Engineering -UNSW Sydney",
            pic: Test3
        },
                {
            text: "I learnt a lot of things, but I really developed in terms of problem solving and critical thinking.",
            person: "Mwesige Brian",
            location: "Civil Engineering, Uganda Martyrs University.",
            pic: Test5
        },
        {
            text: "Working with ASEI reinforced my views on engineering in society as important and beneficial to communities.",
            person: "Elena Aruldoss",
            location: "Mechanical and Manufacturing Engineering -UNSW Sydney",
           pic: Test2,
        },

        {
         text:"Learning about UV-C technology excited me the most. Water quality testing made me appreciate the course I had done." ,
         person:"Paul Ategeka",
         location:"Agricultural Engineering -Makerere University",
          pic: Test1
        },
    ],
}
export const NewsletterTexts = {
    firstText: "Subscribe to get information, latest news and other interesting offers about Jadoo.",
    placeholderText: "Your email",
    buttonText: "Subscribe"
}
export const ContactFormTexts = {
    firstText: "Talk to us your through this form",
    placeholderText: "Your full name ",
    placeholderText2: "Your email",
    placeholderText3: "You Phone number",
    placeholderText4: "Describe your project",
    buttonText: "Send"
}
export const FooterTexts = {
   underLogoText: "Find your next industry project in minutes, gain valuable experience for much longer.",
    quickLinks: {
        caption: "Product",
        links: [
            {
                name: "How it works",
                url: "/about"
            },
                        {
                name: "Educators",
                url: "/about"
            },
                        {
                name: "Students",
                url: "/about"
            },
                        {
                name: "Services",
                url: "/about"
            },
                        {
                name: "Partner with us",
                url: "/contact"
            },
                        {
                name: "Projects",
                url: "/project"
            },
            {
                name: "Pricing",
                url: "/contact"
            },
          
        ]
    },
    contacts: {
        caption: "Company",
        links: [
            {
                name: "About us",
                url: "/about"
            },
            {
                name: "Contact us",
                url: "/contact"
            },
            {
                name: "FAQs",
                url: "/faq"
            },

        ]
    },
    legal: {
        caption: "Legal",
        links: [
            {
                name: "Policies",
                url: "/policy"
            },
            {
                name: "Terms & Conditions",
                url: "policy"
            }
        ]
    },
        resource: {
        caption: "Resources",
        links: [
            {
                name: "Help & support",
                url: "/contact"
            },
            {
                name: "Blog",
                url: "/"
            }
        ]
    },
        followus: {
        caption: "Follow us",
        links: [
            {
                name: "Facebook",
                url: "https://m.facebook.com/profile.php/?id=61555015211002"
            },
            {
                name: "Reddit",
                url: "https://reddit.com/r/Industry_Experiences"
            },
                        {
                name: "LinkedIn",
                url: "https://www.linkedin.com/company/n-industryexperiences/"
            },
            {
              name:"Instagram",
              url: "/"
              
              
            }
        ]
    },
}
export const BookButtonText = {
  
  text1: "Schedule a call with our experts to dive into our projects",
  buttonText: "schedule call"
  
  
  
}
export const ProjectList = [
    {
      imageUrl: ProjectImg1,
      title: "Technology",
      description: "Develop street maps for a new secondary city in Uganda.",
      type: "Mapping",
      location: "Uganda, East Africa",
      status: "Booked",
      type1: "Data science",
      type2:" Computer",
      type3:"programming",
    },
    {
      imageUrl: ProjectImg2,
      title: "Water access",
      description: "Improve sustainability of hand pumps through a digital solution.",
      type: "Water",
      location: "Uganda, East Africa",
      status: "Booked",
      type1: "Geography",
      type2: "Sanitation",
        type3: " Water",
    },
    {
      imageUrl: ProjectImg3,
      title: "Technology",
      description: "Develop street maps for a new secondary city in Uganda.",
      type: "Mapping",
      location: "Uganda, East Africa",
      status: "Booked",
      type1: "Data science",
      type2:"Computer",
      type3:"programming",
    },
    {
      imageUrl: ProjectImg4,
      title: "Water access",
      description: "Provide safe water for African leaving in rural areas",
      type: "Mapping",
      location: "Uganda, East Africa",
      status: "Open",
      type1: "Engineering",
      type2: "Technology",
      type3: "Programming"
    },
  /**  {
      imageUrl: ProjectImg5,
      title: "Agriculture",
      description: "Progress of coffee farming in uganda.",
      type: "Mapping",
      location: "Uganda, East Africa",
      status: "Open",
      type1: "Food security",
      type2: "Nutrition",
      type3: "Bio-tech"
    },**/
    {
      imageUrl: ProjectImg6,
      title: "Fintech",
      description: "Giving acess to digital financial inclusion",
      type: "Technology",
      location: "Uganda, East Africa",
      status: "pending",
      type1: "AI",
      type2: "Technology",
      type3: "Urbanizing",
    },
  ];