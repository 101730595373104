import React, { useState, useEffect } from "react";
import axios from "axios";

const ProjectTable = () => {
    const MAX_COUNT = 5;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [TagsData, setTags] = useState([]);
    const [SkillData, setSkills] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);

    const [projectName, setProjectName] = useState("");
    const [shortBio, setShortBio] = useState("");
    const [displayImage, setDisplayImage] = useState(null);
    const [moreImages, setMoreImages] = useState([]);
    const [about, setAbout] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
  const [Projects, setProjects] =useState([]);
  
      useEffect(() => {
        const fetchData = async () => {
            try {
    const response = await axios.post("https://ni-experiences.com/server/data.php");
                
                setProjects(response.data.projects)
                          console.log(response.data.projects)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(); // Call the fetchData function when the component mounts
    }, []);
  
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post("https://ni-experiences.com/server/getTags.php");
                setTags(response.data.data);
                setSkills(response.data.skills);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(); // Call the fetchData function when the component mounts
    }, []);

    const handleProjectNameChange = (e) => {
        setProjectName(e.target.value);
    };

    const handleShortBioChange = (e) => {
        setShortBio(e.target.value);
    };

    const handleDisplayImageChange = (e) => {
      console.log(e.target.files[0])
        setDisplayImage(e.target.files[0]);
    };

    const handleMoreImagesChange = (e) => {
        const files = Array.from(e.target.files);
        setMoreImages(files);
    };

    const handleAboutChange = (e) => {
        setAbout(e.target.value);
    };

    const handleTagChange = (e) => {
        const selectedOptions = Array.from(e.target.options)
            .filter((option) => option.selected)
            .map((option) => option.value);
        setSelectedTags(selectedOptions);
    };

    const handleSkillChange = (e) => {
        const selectedOptions = Array.from(e.target.options)
            .filter((option) => option.selected)
            .map((option) => option.value);
        setSelectedSkills(selectedOptions);
    };

const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const requestData = {
            projectName: projectName,
            shortbio: shortBio,
            dp: displayImage,
            about: about,
            tags: selectedTags,
            skills: selectedSkills,

            images: moreImages
        };

        console.log(requestData); // Check if requestData is populated correctly

        const response = await axios.post(
            "https://ni-experiences.com/server/product.php",
            requestData,
            {
                headers: {
                    "Content-Type": "application/json", // Set content type to JSON
                },
            }
        );

        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
};
    return (
    <section className="transfer-section">
        <div className="transfer-section-header">
            <h2>Projects</h2>
            <div className="filter-options">
                <button className="save-button" onClick={() => setIsModalOpen(true)}>
                    Add project
                </button>
            </div>
        </div>
        <div className="table-container-scrollable">
            <div className="table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Project Name</th>
                            <th>Status</th>
                            <th>Delete</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Project A</td>
                            <td>active</td>
                            <td><button className="save-button">Delete</button></td>
                            <td><button className="save-button">Edit</button></td>
                        </tr>
                        <tr>
                            <td>Project B</td>
                            <td>Completed</td>
                            <td><button className="save-button">Delete</button></td>
                            <td><button>Edit</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        {isModalOpen && (
            <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
                    <h2>Add Project</h2>
                    <form>
                        <label htmlFor="projectName">Project Name:</label>
                        <input type="text"  name="projectName" value={projectName} onChange={handleProjectNameChange} required />
                        <label htmlFor="shortBio">Short bio:</label>
                        <input type="text"  name="shortBio" value={shortBio} onChange={handleShortBioChange} required />
                        <label htmlFor="displayImage">Upload Display Image:</label>
                        <input type="file"  name="displayImage" onChange={handleDisplayImageChange} />
                        <label htmlFor="moreImages">More images:</label>
                        <input type="file" name="moreImages" onChange={handleMoreImagesChange} multiple />
                        <label htmlFor="about">About:</label>
                        <textarea name="about" value={about} onChange={handleAboutChange}></textarea>

                        {/* Tags selection */}
                        <label htmlFor="tags">Tags:</label>
                        <select name="tags" multiple onChange={handleTagChange}>
                            {TagsData.map((tag) => (
                                <option key={tag.id} value={tag.id}>{tag.name}</option>
                            ))}
                        </select>

                        {/* Skills selection */}
                        <label htmlFor="skills">Skills:</label>
                        <select name="skills" multiple onChange={handleSkillChange}>
                            {SkillData.map((skill) => (
                                <option key={skill.id} value={skill.id}>{skill.name}</option>
                            ))}
                        </select>

                        <button onClick={handleSubmit}>Submit</button>
                    </form>
                </div>
            </div>
        )}
    </section>
);
};

export default ProjectTable;