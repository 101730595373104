import React from "react";


 function Policy() {
  
  
  return (
    <>
    <div style={{marginTop:"140px"}}>


<div class="container mt-5">
    <h2 class="mb-4">Privacy Policy</h2>
    <p>Welcome to the Privacy Policy of Index (“we”, “us” or “our”). We are dedicated to safeguarding your privacy and ensuring the protection of your personal information. This Privacy Policy outlines how we collect, use, store, share, and disclose your personal information.</p>
    <p><b>Openness and Transparency</b></p>
    <p>We are committed to respecting your privacy rights under the Uganda Data Protection and Privacy Act, 2019. We pledge to adhere to Uganda's data protection laws and to address any inquiries or complaints regarding compliance promptly.</p>
    <p>By accessing and utilizing our website, products, and services, you agree to the collection, use, storage, and disclosure of your personal information as outlined in this Privacy Policy.</p>
    <h2><b>Personal Information</b></h2>
    <p>Personal information refers to data or opinions about an individual whose identity is apparent or reasonably ascertainable from the information or opinion (regardless of its accuracy or form).</p>
    <p>The personal information we collect includes, but is not limited to:</p>
    <ol>
        <li>Full name</li>
        <li>Address</li>
        <li>Email address</li>
        <li>Telephone number(s)</li>
        <li>Credit card information</li>
        <li>Device ID, type, and geo-location</li>
        <li>Website usage statistics</li>
        <li>Details of products and services provided or inquired about.</li>
        <li>Any additional information provided directly or indirectly.</li>
    </ol>
    <p>    <h2><b>Collection
    </b></h2>
    </p>
    <p>We collect personal information lawfully and fairly, avoiding intrusive methods. Typically, we gather data directly from you to fulfill requested products/services or meet legal obligations. Sensitive information is not collected.</p>
    <p>Personal information may also be obtained when you interact with us, whether through forms, communication, website visits, feedback, surveys, or events. Additionally, we may receive information from business partners or third parties.</p>
    <p>We require accurate, up-to-date, and complete personal information for effective service delivery.</p>
    <p>Use of Personal Information</p>
    <p>Your personal information is used and disclosed for purposes including, but not limited to:</p>
    <ul>
        <li>
        Providing products and services</li>
        <li>Administering, improving, or optimizing our offerings</li>
        <li>Billing for products/services</li>
        <li>Informing about promotions or events</li>
        <li>Responding to inquiries</li>
        <li>Verifying identity</li>
        <li>Other purposes consented to or required by law.</li>
    </ul>
    <p>Disclosure of Personal Information</p>
    <p>We may disclose your personal information to:</p>
    <ul>
        <li>Third parties for operational purposes</li>
        <li>Entities with your consent</li>
        <li>External advisors, auditors, lawyers, insurers, and financiers</li>
        <li>Entities as required or authorized by law.</li>
    </ul>
    <p>Access and Management</p>
    <p>You may request access to or correction of your personal information in our database, except where exempted by law. Requests for access or correction will be addressed promptly, with explanations provided for refusals.</p>
    <p>Direct Marketing</p>
    <p>With your consent or as permitted by law, we may send you marketing communications tailored to your interests. You may opt out of these communications at any time.</p>
    <p>Cross-border Disclosure</p>
    <p>Your personal information may be disclosed to recipients outside Uganda for service provision. By engaging with us, you consent to such disclosure, acknowledging our limited liability for recipient compliance with Ugandan privacy laws.</p>
    <p>Website Usage</p>
    <p>Transmission of personal information over the Internet is not entirely secure. While we take precautions, we cannot guarantee absolute security.</p>
    <p>Our website may use cookies or similar tracking technologies. You can adjust cookie settings in your browser, although this may affect website functionality.</p>
    <p>Security</p>
    <p>We employ various measures to protect personal information from unauthorized access, modification, or disclosure. Despite our efforts, transmission of information over the internet entails risk, and we disclaim liability for any unauthorized access or misuse.</p>
    <p>Contact Information</p>
    <p>For inquiries or privacy complaints, please contact us at support@ni-experiences.com.</p>
    <p>Miscellaneous</p>
    <p>We reserve the right to modify this Privacy Policy without notice. Amendments become effective immediately upon posting on the Index website.</p>
    <p>Thank you for reviewing our Privacy Policy.</p>
</div>


<div class="container mt-5">
    <h2 class="mb-4">Terms of Service</h2>
    <p>These Terms of Use ("Terms") govern your access to and use of the industry experiences booking services provided by our website, Index (the "Service"). Index is managed by ASEI (African STEM Education Initiative Limited), a company limited by guarantee located in Fort Portal, Uganda. By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from accessing or using the Service.</p>
    <p>Definitions and Interpretation</p>
    <p>In this Agreement the following terms shall have the following meanings:</p>
    <ul>
        <li>"Account": means collectively the personal information, Payment Information and credentials used by Users to access Paid Content and / or any communications System on the Website;</li>
        
        
        <li>"Content": means any text, graphics, images, audio, video, software, data compilations and any other form of information capable of being stored in a computer that appears on or forms part of this Website;</li>
        <li>"Facilities": means collectively any online facilities, tools, services or information that Index makes available through the Website either now or in the future;</li>
        <li>"Services": means the services available to you through this Website.</li>
        <li>"Payment Information": means any details required for the purchase of Services from this Website. This includes, but is not limited to, credit / debit card numbers, bank account numbers and sort codes;</li>
        <li>"Premises": Means Our place(s) of business located at Nyaika Avenue, Boma – Fort Portal, Uganda.</li>
        <li>"System": means any online communications infrastructure that Index makes available through the Website either now or in the future. This includes, but is not limited to, web-based email, message boards, live chat facilities and email links;</li>
        <li>"User" / "Users": means any third party that accesses the Website and is not employed by Index.</li>
        <li>"Website": means the website that you are currently using (https://www.ni-experiences.com) and any sub-domains of this site unless expressly excluded by their own terms and conditions; and</li>
        <li>"We/Us/Our": means Index a product of African STEM Education Initiative Limited, a company limited by guarantee incorporated in Uganda with registration number.</li>
    </ul>
    <p>Scope of Service</p>
    <p>Our Service provides a platform for users to book industry experiences in Uganda. These experiences may include but are not limited to internships, apprenticeships, and vocational training.</p>
    <p>User Conduct</p>
    <p>a. You agree to use the Service solely for lawful purposes and in a manner consistent with these Terms and all applicable laws and regulations.</p>
    <p>b. You agree not to use the Service in any way that could damage, disable, overburden, or impair the Service or interfere with any other party's use and enjoyment of the Service.</p>
    <p>c. You agree not to attempt to gain unauthorized access to any portion or feature of the Service, or any other systems or networks connected to the Service.</p>
    <p>Booking Process</p>
    <p>a. Users may browse available industry experiences listed on the website.</p>
    <p>b. To book an experience, users must provide accurate and complete information as prompted by the booking form.</p>
    <p>c. Upon booking confirmation, users agree to adhere to the terms and conditions set forth by the experience provider.</p>
    <p>Payments</p>
    <p>a. Users may be required to make payments to confirm their bookings.</p>
    <p>b. All payments made through the Service are subject to our payment terms and privacy policy.</p>
    <p>c. We are not responsible for any additional charges, fees, or taxes incurred as a result of your use of the Service.</p>
    <p>    <h2><b>Third Party Intellectual Property     </b></h2></p>
    <p>a. Some industry experiences listed on the Service may be subject to third-party intellectual property rights. Users agree to respect and abide by any such rights when participating in these experiences.</p>
    <p>Links to Other Websites</p>
    <p>a. The Service may contain links to third-party websites or resources. These links are provided for your convenience only and do not imply any endorsement by us of the linked website or resource.</p>
    <p>b. We are not responsible for the content, products, or services offered on third-party websites, and your use of such websites is subject to the terms and conditions and privacy policies of the respective websites.</p>
    <p>     <h2><b>Links to This Website
    
        </b></h2></p>
    <p>a. You may link to our website, provided that you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
    <p>b. You may not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part where none exists.</p>
    <p>Use of Communication Facilities on This Website</p>
    <p>a. The Service may include communication tools such as forums, chat rooms, or messaging features. You agree to use these tools responsibly and in accordance with these Terms and all applicable laws and regulations.</p>
    <p>b. We reserve the right to monitor or remove any content posted or transmitted through these communication tools that we believe violates these Terms or is otherwise objectionable.</p>
    <p>    <h2><b>User Accounts    </b></h2></p>
    <p>a. To access certain features of the Service, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
    <p>b. You agree to provide accurate and complete information when creating your account and to update your information promptly if it changes.</p>
    <p>    <h2><b>Disclaimer of     </b></h2></p>
    <p>a. The Service is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied.</p>
    <p>b. We do not warrant that the Service will be uninterrupted, secure, or error-free, or that any defects or errors will be corrected.</p>
    <p>Limitation of Liability</p>
    <p>a. In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, lost profits, lost data, or business interruption, arising out of or in connection with your use of the Service.</p>
    <p>b. Our total liability to you for any and all claims arising out of or in connection with these Terms or your use of the Service shall not exceed the amount paid by you, if any, for accessing the Service.</p>
    <p>    <h2><b>Governing Law    </b></h2></p>
    <p>These Terms shall be governed by and construed in accordance with the laws of the Republic of Uganda. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Uganda.</p>
    <p>Modifications to the Terms of Use.</p>
    <p>We reserve the right to modify or replace these Terms at any time. It is your responsibility to review these Terms periodically for changes. Your continued use of the Service following the posting of any changes to these Terms constitutes acceptance of those changes.</p>
    <p>    <h2><b>Contact Us    </b></h2></p>
    If you have any questions or concerns about
    <ul>
        <li>"Content": means any text, graphics, images, audio, video, software, data compilations and any other form of information capable of being stored in a computer that appears on or forms part of this Website;</li>
        <li>"Facilities": means collectively any online facilities, tools, services or information that Index makes available through the Website either now or in the future;</li>
        <li>"Services": means the services available to you through this Website.</li>
        <li>"Payment Information": means any details required for the purchase of Services from this Website. This includes, but is not limited to, credit / debit card numbers, bank account numbers and sort codes;</li>
        <li>"Premises": Means Our place(s) of business located at Nyaika Avenue, Boma – Fort Portal, Uganda.</li>
        <li>"System": means any online communications infrastructure that Index makes available through the Website either now or in the future. This includes, but is not limited to, web-based email, message boards, live chat facilities and email links;</li>
        <li>"User" / "Users": means any third party that accesses the Website and is not employed by Index.</li>
        <li>"Website": means the website that you are currently using (https://www.ni-experiences.com) and any sub-domains of this site unless expressly excluded by their own terms and conditions; and</li>
        <li>"We/Us/Our": means Index a product of African STEM Education Initiative Limited, a company limited by guarantee incorporated in Uganda with registration number.</li>
    </ul>
    <p>    <h2><b>Scope of Service    </b></h2></p>
    <p>Our Service provides a platform for users to book industry experiences in Uganda. These experiences may include but are not limited to internships, apprenticeships, and vocational training.</p>
    <p>    <h2><b>User Conduct    </b></h2></p>
    <p>a. You agree to use the Service solely for lawful purposes and in a manner consistent with these Terms and all applicable laws and regulations.</p>
    <p>b. You agree not to use the Service in any way that could damage, disable, overburden, or impair the Service or interfere with any other party's use and enjoyment of the Service.</p>
    <p>c. You agree not to attempt to gain unauthorized access to any portion or feature of the Service, or any other systems or networks connected to the Service.</p>
    <p>    <h2><b>Booking Process    </b></h2></p>
    <p>a. Users may browse available industry experiences listed on the website.</p>
    <p>b. To book an experience, users must provide accurate and complete information as prompted by the booking form.</p>
    <p>c. Upon booking confirmation, users agree to adhere to the terms and conditions set forth by the experience provider.</p>
    <p>    <h2><b>Payments    </b></h2></p>
    <p>a. Users may be required to make payments to confirm their bookings.</p>
    <p>b. All payments made through the Service are subject to our payment terms and privacy policy.</p>
    <p>c. We are not responsible for any additional charges, fees, or taxes incurred as a result of your use of the Service.</p>
    <p>    <h2><b>Third Party Intellectual Property    </b></h2></p>
    <p>a. Some industry experiences listed on the Service may be subject to third-party intellectual property rights. Users agree to respect and abide by any such rights when participating in these experiences.</p>
    <p>    <h2><b>Links to Other Websites    </b></h2></p>
    <p>a. The Service may contain links to third-party websites or resources. These links are provided for your convenience only and do not imply any endorsement by us of the linked website or resource.</p>
    <p>b. We are not responsible for the content, products, or services offered on third-party websites, and your use of such websites is subject to the terms and conditions and privacy policies of the respective websites.</p>
    <p>    <h2><b>Links to This Website    </b></h2></p>
    <p>a. You may link to our website, provided that you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
    <p>b. You may not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part where none exists.</p>
    <p>Use of Communication Facilities on This Website</p>
    <p>a. The Service may include communication tools such as forums, chat rooms, or messaging features. You agree to use these tools responsibly and in accordance with these Terms and all applicable laws and regulations.</p>
    <p>b. We reserve the right to monitor or remove any content posted or transmitted through these communication tools that we believe violates these Terms or is otherwise objectionable.</p>
    <p>  <h2><b>User Accounts    </b></h2></p>
    <p>a. To access certain features of the Service, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
    <p>b. You agree to provide accurate and complete information when creating your account and to update your information promptly if it changes.</p>
    <p>Disclaimer of Warranties</p>
    <p>a. The Service is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied.</p>
    <p>b. We do not warrant that the Service will be uninterrupted, secure, or error-free, or that any defects or errors will be corrected.</p>
    <p>Limitation of Liability</p>
    <p>a. In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, lost profits, lost data, or business interruption, arising out of or in connection with your use of the Service.</p>
    <p>b. Our total liability to you for any and all claims arising out of or in connection with these Terms or your use of the Service shall not exceed the amount paid by you, if any, for accessing the Service.</p>
    <p>Governing Law</p>
    <p>These Terms shall be governed by and construed in accordance with the laws of the Republic of Uganda. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Uganda.</p>
    <p>Modifications to the Terms of Use.</p>
    <p>We reserve the right to modify or replace these Terms at any time. It is your responsibility to review these Terms periodically for changes. Your continued use of the Service following the posting of any changes to these Terms constitutes acceptance of those changes.</p>
    <p>Contact Us</p>
    <p>If you have any questions or concerns about these Terms, please contact us at support@ni-experiences.com.</p>
    <p>By accessing or using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>
</div>
</div>
    </>
    
    
    
    
    )
  
  
  
  
}

export default Policy;