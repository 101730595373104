import React from "react";
// Sections
import FaqUpperSection from "../components/Sections/FaqUpperSection"
import Question from "../components/Sections/Questions"


const Faqs= () => {
    return (
        <>
            <FaqUpperSection />
            <Question/>
          
        </>
    )
}

export default Faqs